



















































































import {
  Vue,
  Component,
  PropSync,
  Watch,
} from 'vue-property-decorator';
import { VForm } from '@/vue-app/types/vuetify';
import { getScreenSizeVariant } from '@/vue-app/utils/screen';
import ContractSavingsPersonalInformationContactAllianzViewModel
  from '@/vue-app/view-models/components/contract-savings/personal-information/contact/contract-savings-personal-information-contact-allianz-view-model';

@Component({ name: 'ContractSavingsPersonalInformationContactAllianz' })
export default class ContractSavingsPersonalInformationContactAllianz extends Vue {
  @PropSync('contactForm', { type: Boolean })
  synced_contact_form!: boolean;

  $refs!: {
    contact_form: VForm;
  }

  personal_info_contact_allianz_view_model = Vue.observable(
    new ContractSavingsPersonalInformationContactAllianzViewModel(),
  );

  @Watch('personal_info_contact_allianz_view_model.is_loading')
  onLoadChange(is_loading: boolean) {
    this.$emit('loading', is_loading);
  }

  async created() {
    this.personal_info_contact_allianz_view_model.small_screen = getScreenSizeVariant() === 'small';
    await this.personal_info_contact_allianz_view_model.initialize();
  }

  async saveStep() {
    const saved = await this.personal_info_contact_allianz_view_model.saveStep();
    return saved;
  }
}

