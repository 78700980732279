import TYPES from '@/types';
import i18n from '@/vue-app/plugins/i18n';
import { v4 as uuid } from 'uuid';
import { emailFormat, phoneFormat, requiredRule } from '@/vue-app/utils/form-rules';

// Application
import { SearchOnBoardingStepsQuery }
  from '@/modules/my-investment/on-boarding-steps/application/queries';
import { SearchInvestmentProvidersQuery }
  from '@/modules/my-investment/investment-provider/application/queries';
import { GetCustomerPersonalInformationQuery }
  from '@/modules/customer/personal-information/application/queries';
import {
  CreateOnBoardingStepCommand,
  UpdateOnBoardingStepCommand,
} from '@/modules/my-investment/on-boarding-steps/application/commands';

// Domain
import {
  OnBoardingStepEntity,
} from '@/modules/my-investment/on-boarding-steps/domain/entities/on-boarding-step-entity';
import {
  InvestmentProviderEntity,
} from '@/modules/my-investment/investment-provider/domain/entities/investment-provider-entity';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Inject from '@/modules/shared/domain/di/inject';

export default class ContractSavingsPersonalInformationContactAllianzViewModel {
  @Inject(TYPES.SEARCH_MY_INVESTMENT_ON_BOARDING_STEP_REPOSITORY)
  private readonly searchOnBoardingStepsQuery!: SearchOnBoardingStepsQuery;

  @Inject(TYPES.SEARCH_INVESTMENT_PROVIDER_QUERY)
  private readonly searchInvestmentProvidersQuery!: SearchInvestmentProvidersQuery;

  @Inject(TYPES.GET_CUSTOMER_PERSONAL_INFORMATION_QUERY)
  private readonly get_customer_personal_information_query!: GetCustomerPersonalInformationQuery;

  @Inject(TYPES.CREATE_MY_INVESTMENT_ON_BOARDING_STEP_COMMAND)
  private readonly createOnBoardingStepCommand!: CreateOnBoardingStepCommand;

  @Inject(TYPES.UPDATE_MY_INVESTMENT_ON_BOARDING_STEP_COMMAND)
  private readonly updateOnBoardingStepCommand!: UpdateOnBoardingStepCommand;

  @Inject(TYPES.NOTIFIER)
  readonly messageNotifier!: MessageNotifier;

  readonly i18n_namespace = 'components.contract-savings.personal-information.contact';

  readonly error_message = i18n.tc('components.contract-savings.error_on_save_step');

  small_screen: null | boolean = null;

  country_codes = ['+52', '+1'];

  inputs = {
    email: '',
    phone_number: '',
    cellphone_number: '',
    phone_country_code: this.country_codes[0],
    cellphone_country_code: this.country_codes[0],
  };

  rules = {
    phone: [
      requiredRule,
      (value: string) => phoneFormat('+52', value),
    ],
    home_phone: [
      (value: string) => phoneFormat('+52', value),
    ],
    email: [requiredRule, emailFormat],
  };

  investment_provider_name = 'allianz';

  exists_step = false;

  step_name = 'contact_information';

  investment_provider_id = '';

  is_loading = true;

  on_boarding_step: OnBoardingStepEntity = {
    id: uuid(),
    current_step: '',
    investment_provider_id: '',
    payload: {
      email: '',
      phone_number: '',
      cellphone_number: '',
      phone_country_code: '',
      cellphone_country_code: '',
    },
  };

  verifyStep = async (on_boarding_steps: Array<OnBoardingStepEntity>) => {
    if (on_boarding_steps.length) {
      const search_step = on_boarding_steps.find(
        (step) => step.current_step === this.step_name,
      );
      if (search_step) {
        this.exists_step = true;
        this.on_boarding_step = search_step;
        this.setInputsDataFromStep();
      } else {
        await this.loadDefaultInformation();
      }
    }
  };

  setInputsDataFromStep() {
    this.inputs.cellphone_number = this.on_boarding_step.payload.cellphone_number;
    this.inputs.cellphone_country_code = this.on_boarding_step.payload.cellphone_country_code;
    this.inputs.phone_number = this.on_boarding_step.payload.phone_number;
    this.inputs.phone_country_code = this.on_boarding_step.payload.phone_country_code;
    this.inputs.email = this.on_boarding_step.payload.email;
  }

  setInvestmentProviderId = (investment_providers: Array<InvestmentProviderEntity>) => {
    if (investment_providers.length) {
      const search_provider = investment_providers.find(
        (item) => item.name === this.investment_provider_name,
      );
      if (search_provider) {
        this.investment_provider_id = search_provider.id;
      }
    }
  };

  get investmentProviders() {
    return this.searchInvestmentProvidersQuery.execute();
  }

  get steps() {
    return this.searchOnBoardingStepsQuery.execute(this.investment_provider_id);
  }

  loadDefaultInformation = async () => {
    const {
      email, cellphone_country_code, cellphone, phone_country_code, phone,
    } = await this.get_customer_personal_information_query.execute();
    this.inputs.email = email;
    this.inputs.cellphone_country_code = cellphone_country_code || this.country_codes[0];
    this.inputs.cellphone_number = cellphone;
    this.inputs.phone_country_code = phone_country_code || this.country_codes[0];
    this.inputs.phone_number = phone;
  }

  async saveStep() {
    this.is_loading = true;
    try {
      this.on_boarding_step.current_step = this.step_name;
      this.on_boarding_step.investment_provider_id = this.investment_provider_id;
      this.on_boarding_step.payload = {
        email: this.inputs.email,
        cellphone_number: this.inputs.cellphone_number.replace(/\D/g, ''),
        phone_number: this.inputs.phone_number.replace(/\D/g, ''),
        cellphone_country_code: this.inputs.cellphone_country_code,
        phone_country_code: this.inputs.phone_country_code,
      };
      if (this.exists_step) {
        delete this.on_boarding_step.customer_id;
        await this.updateOnBoardingStepCommand.execute(this.on_boarding_step);
      } else {
        await this.createOnBoardingStepCommand.execute(this.on_boarding_step);
      }
    } catch {
      this.messageNotifier.showErrorNotification(this.error_message);
      this.is_loading = false;
      return false;
    }
    this.is_loading = false;
    return true;
  }

  initialize = async () => {
    const investment_providers = await this.investmentProviders;
    this.setInvestmentProviderId(investment_providers);

    const steps = await this.steps;
    this.verifyStep(steps);

    this.is_loading = false;
  }
}
